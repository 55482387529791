<template>
	<div>
		<SettingsAddGoogleDrive :g-drive-emails="gDriveEmails" :max-g-drive-emails="currentUser.limits ? currentUser.limits.gDriveEmails : 0" />
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: `${this.$t('nav.settings')} - Google Drive`,
		}
	},
	components: {
		SettingsAddGoogleDrive: () => import('@/components/settings/SettingsAddGoogleDrive.vue'),
	},
	computed: {
		...mapGetters({
			currentUser: 'user/currentUser',
			gDriveEmails: 'gdriveemails/gDriveEmails',
		}),
	},
	created() {
		this.fetchGDriveEmails()
	},
	methods: {
		...mapActions('gdriveemails', ['fetchGDriveEmails']),
	},
}
</script>

<style scoped></style>
